html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: white;
  font-family: "Lucida Console", Monaco, monospace;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;
  background-color: black;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: underline;
}

a:visited {
  color: white;
  text-decoration: none;
}

p {
  font-size: 0.75em;
}

h2 {
  font-size: 1em;
  width: 100%;
}
