h2 {
  margin-bottom: 0;
}

.header {
  display: flex;
  flex-direction: column;
  height: 95vh;
}

.content {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
}

.top {
  color: white;
  text-align: center;
  font-family: "Lucida Console", Monaco, monospace;
  z-index: 1;
  height: 100vh;
  overflow-y: scroll;

  scroll-snap-type: y mandatory;
}

.top section {
  scroll-snap-align: center;
}
